@import "font/poppins.css";
@import "font/work-sans.css";
@import "font/space-mono.css";
@import "layout/toast.module.css";

html,
body {
    font-family: "Poppins", sans-serif;
    min-height: 100vh;
}

#__next {
    min-height: 100vh;
}

.ReactModal__Overlay {
    z-index: 4;
}
