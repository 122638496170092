.image-gallery-icon {
    color: #fff;
    transition: all 0.2s ease-out;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    filter: drop-shadow(0 2px 2px #1a1a1a);
}

@media (min-width: 768px) {
    .image-gallery-icon:hover {
        color: #32afe4;
    }

    .image-gallery-icon:hover .image-gallery-svg {
        transform: scale(1.1);
        fill: #2ca5e0;
    }

    .image-gallery-icon:hover .image-gallery-close-svg {
        fill: #2ca5e0;
    }
}

.image-gallery-icon:focus {
    outline: 2px solid #337ab7;
}

.image-gallery-enlarge-button {
    position: absolute;
    top: 14px;
    right: 10px;
    font-size: 16px;
    color: #fff;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    z-index: 4;
    filter: drop-shadow(0 2px 1px rgba(26, 26, 26, 0.5));
    transition: all 0.2s ease-out;
}

.image-gallery-enlarge-button svg {
    stroke: #fff;
    float: right;
    margin-top: -3px;
    margin-left: 8px;
    transition: all 0.2s ease-out;
}

.image-gallery-enlarge-button:hover {
    color: #2ca5e0;
}

.image-gallery-enlarge-button:hover svg {
    stroke: #2ca5e0;
}

@media (min-width: 990px) {
    .image-gallery-enlarge-button {
        top: 24px;
        right: 20px;
    }
}

.image-gallery-using-mouse .image-gallery-icon:focus {
    outline: none;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
    bottom: 0;
    padding: 20px;
}

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
    height: 40px;
    width: 40px;
    fill: #fff;
}

.image-gallery-fullscreen-button.active {
    width: 30px;
    height: 30px;
    border-radius: 3px;
    padding: 5px;
    background: rgba(0, 0, 0, 0.3);
    filter: none;
}

.image-gallery.fullscreen-modal .image-gallery-fullscreen-button.active {
    top: 20px;
    right: 20px;
}

.image-gallery-fullscreen-button .image-gallery-close-svg {
    height: 20px;
    width: 20px;
    fill: #fff;
}

@media (min-width: 990px) {
    .image-gallery.fullscreen-modal .image-gallery-fullscreen-button.active {
        top: 50px;
        right: 30px;
    }
}

@media (max-width: 768px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
        padding: 10px;
    }
}

.image-gallery-fullscreen-button {
    right: 0;
}

.image-gallery-play-button {
    left: 0;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
    padding: 50px 10px;
    top: 50%;
    transform: translateY(-50%);
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 60px;
}

@media (max-width: 768px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        height: 72px;
        width: 36px;
    }
}

@media (max-width: 480px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        height: 48px;
        width: 24px;
    }
}

.image-gallery-left-nav[disabled],
.image-gallery-right-nav[disabled] {
    cursor: disabled;
    opacity: 0.6;
    pointer-events: none;
}

.image-gallery-left-nav {
    left: 0;
}

.image-gallery-right-nav {
    right: 0;
}

.image-gallery {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    position: relative;
}

.image-gallery.fullscreen-modal {
    background: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5;
}

.image-gallery.fullscreen-modal .image-gallery-content {
    top: 50%;
    transform: translateY(-50%);
}

.image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0;
}

.image-gallery-content.fullscreen {
    background: #000;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px);
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
    max-height: 100vh;
}

.image-gallery-slide-wrapper {
    position: relative;
}

.image-gallery-slide-wrapper.left,
.image-gallery-slide-wrapper.right {
    display: inline-block;
    width: calc(100% - 110px);
}

@media (max-width: 768px) {
    .image-gallery-slide-wrapper.left,
    .image-gallery-slide-wrapper.right {
        width: calc(100% - 87px);
    }
}

.image-gallery-slide-wrapper.image-gallery-rtl {
    direction: rtl;
}

.image-gallery-slides {
    line-height: 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-align: center;
}

.image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.image-gallery-slide.center {
    position: relative;
}

.image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: contain;
}

.image-gallery-slide .image-gallery-description {
    background: rgba(0, 0, 0, 0.4);
    bottom: 70px;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal;
}

@media (max-width: 768px) {
    .image-gallery-slide .image-gallery-description {
        bottom: 45px;
        font-size: 0.8em;
        padding: 8px 15px;
    }
}

.image-gallery-bullets {
    bottom: 20px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 80%;
    z-index: 4;
}

.image-gallery-bullets .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center;
}

.image-gallery-bullets .image-gallery-bullet {
    appearance: none;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 0 1px 0 #1a1a1a;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: none;
    padding: 5px;
    transition: background 0.2s ease-out;
}

@media (max-width: 768px) {
    .image-gallery-bullets .image-gallery-bullet {
        margin: 0 3px;
        padding: 3px;
    }
}

@media (max-width: 480px) {
    .image-gallery-bullets .image-gallery-bullet {
        padding: 2.7px;
    }
}

.image-gallery-bullets .image-gallery-bullet:focus,
.image-gallery-bullets .image-gallery-bullet:hover {
    background: #337ab7;
    transform: scale(1.1);
}

.image-gallery-bullets .image-gallery-bullet.active {
    background: #fff;
}

.image-gallery-thumbnails-wrapper {
    position: relative;
}

.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
    direction: rtl;
}

.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
    display: inline-block;
    vertical-align: top;
    width: 170px;
}

@media (max-width: 768px) {
    .image-gallery-thumbnails-wrapper.left,
    .image-gallery-thumbnails-wrapper.right {
        width: 81px;
    }
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
    height: 100%;
    width: 100%;
    left: 0;
    padding: 0;
    position: absolute;
    top: 0;
}

.image-gallery-thumbnails-wrapper.left
    .image-gallery-thumbnails
    .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right
    .image-gallery-thumbnails
    .image-gallery-thumbnail {
    display: block;
    margin-right: 0;
    padding: 0;
}

.image-gallery-thumbnails-wrapper.left
    .image-gallery-thumbnails
    .image-gallery-thumbnail
    + .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right
    .image-gallery-thumbnails
    .image-gallery-thumbnail
    + .image-gallery-thumbnail {
    margin-left: 0;
    margin-top: 2px;
}

.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
    margin: 0 5px;
}

@media (max-width: 768px) {
    .image-gallery-thumbnails-wrapper.left,
    .image-gallery-thumbnails-wrapper.right {
        margin: 0 3px;
    }
}

.image-gallery-thumbnails {
    overflow: hidden;
    padding: 5px 0;
}

@media (max-width: 768px) {
    .image-gallery-thumbnails {
        padding: 3px 0;
    }
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    transition: transform 0.45s ease-out;
    white-space: nowrap;
}

.image-gallery-thumbnail {
    display: inline-block;
    transition: border 0.3s ease-out;
    border: 2px solid transparent;
    margin: 6px 0 6px 6px;
    width: 172px;
    background: transparent;
    padding: 0;
}

@media (max-width: 768px) {
    .image-gallery-thumbnail {
        border: 2px solid transparent;
        width: 81px;
    }
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
    position: relative;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
    outline: none;
    border: 2px solid #2ca5e0;
}

@media (max-width: 768px) {
    .image-gallery-thumbnail.active,
    .image-gallery-thumbnail:hover,
    .image-gallery-thumbnail:focus {
        border: 2px solid #2ca5e0;
    }
}

.image-gallery-thumbnail-label {
    box-sizing: border-box;
    color: white;
    font-size: 1em;
    left: 0;
    line-height: 1em;
    padding: 5%;
    position: absolute;
    top: 50%;
    text-shadow: 1px 1px 0 black;
    transform: translateY(-50%);
    white-space: normal;
    width: 100%;
}

@media (max-width: 768px) {
    .image-gallery-thumbnail-label {
        font-size: 0.8em;
        line-height: 0.8em;
    }
}

.image-gallery-index {
    display: none;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 3px;
    color: #fff;
    line-height: 1;
    padding: 4px 8px;
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 4;
    font-weight: 500;
}

@media (max-width: 768px) {
    .image-gallery-index {
        font-size: 0.8em;
        padding: 5px 10px;
    }
}

@media (min-width: 600px) {
    .image-gallery.fullscreen-modal .image-gallery-index {
        display: block;
    }
}

@media (min-width: 990px) {
    .image-gallery-index {
        top: 50px;
        left: 60px;
    }
}

.image-gallery-index-standard {
    display: flex;
    align-items: center;
    color: #fff;
    line-height: 1px;
    padding: 4px 8px;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 4;
    font-weight: 500;
    filter: drop-shadow(0 2px 1px rgba(26, 26, 26, 0.5));
}

@media (min-width: 600px) {
    .image-gallery-index-standard {
        top: 20px;
        left: 20px;
    }
}

.image-gallery-index-standard-icon svg {
    fill: #fff;
    width: 26px;
    margin-right: 7px;
}

.image-gallery.fullscreen-modal .image-gallery-index-standard {
    display: none;
}

.image-gallery-details {
    position: absolute;
    display: none;
    bottom: 0;
    width: 100%;
    height: 62px;
    background: rgba(0, 0, 0, 0.15);
    z-index: 4;
    color: #fff;
    line-height: 62px;
    align-items: center;
}

.image-gallery-details-address {
    flex: 1;
    padding-left: 20px;
    font-weight: 600;
    line-height: 20px;
}

.image-gallery-details-price {
    flex: 1;
    text-align: center;
}

.image-gallery-details-price-value {
    font-weight: 600;
}

.image-gallery-details-logo {
    flex: 1;
    padding-right: 20px;
}

.image-gallery-details-logo > img {
    float: right;
    width: 114px;
    height: 19px;
}

@media (min-width: 600px) {
    .image-gallery.fullscreen-modal .image-gallery-details {
        display: flex;
    }
}

@media (min-width: 990px) {
    .image-gallery-details-address {
        padding-left: 62px;
    }

    .image-gallery-details-logo {
        padding-right: 62px;
    }
}

/*
Apple Products
---------------
iPhone XR       414 x 896
iPhone XS       375 x 812
iPhone XS Max   414 x 896
iPhone X	    375 x 812
iPhone 8 Plus   414 x 736
iPhone 8        375 x 667
iPhone 7 Plus   414 x 736
iPhone 7        375 x 667
iPhone 6 Plus   414 x 736
iPhone 6S Plus  414 x 736
iPhone 6/6S     375 x 667
iPhone 5        320 x 568
iPod Touch	    320 x 568
iPad Pro        1024 x 1366
iPad Third      768 x 1024
iPad Fourth G.  768 x 1024
iPad Air 1&2    768 x 1024
iPad Mini 2&3   768 x 1024
iPad Mini       768 x 1024

Android Devices
---------------
Nexus 6P                412 x 732
Nexus 5X                412 x 732
Google Pixel 3 XL       412 x 847
Google Pixel 3          412 x 824
Google Pixel 2 XL	    (411) 412 x 732
Google Pixel XL         412 x 732
Google Pixel            412 x 732
Samsung Galaxy Note 9   360 x 740
Samsung Galaxy Note 5   480 x 853
LG G5                   480 x 853
LG Optimus L70          384 x 640
One Plus 3              480 x 853
Samsung Galaxy S9+      360 x 740
Samsung Galaxy S9       360 x 740
Samsung Galaxy S8+      360 x 740
Samsung Galaxy S8       360 x 740
Samsung Galaxy S7 Edge  360 x 640
Samsung Galaxy S7       360 x 640
Nexus 9                 768 x 1024
Nexus 7                 600 x 960
Samsung Galaxy Tab 10   800 x 1280
Chromebook Pixel        1280 x 850

POSSIBLE HEIGHT IN PIXELS
-----------------------------
320, 360, 375, 384,
411, 412, 414, 480,
568,
600, 640, 667,
732, 736, 740, 768,
800, 812, 824, 847, 850, 853, 896,
960,
1024, 1280, 1366
*/

/*
    ------------------------------------------------------------------------
    Changed "/static/css/image-gallery.css"
    (a copy "/node_modules/react-image-gallery/styles/css/image-gallery.css")
    ------------------------------------------------------------------------
 */

/*
    Page has ONE COLUMN
 */
@media (min-width: 320px) {
    .image-gallery-image img {
        min-height: 172px;
        max-height: 195px;
    }
}

@media (min-width: 360px) {
    .image-gallery-image img {
        min-height: 195px;
        max-height: 203px;
    }
}

@media (min-width: 375px) {
    .image-gallery-image img {
        min-height: 203px;
        max-height: 209px;
    }
}

@media (min-width: 384px) {
    .image-gallery-image img {
        min-height: 209px;
        max-height: 219px;
    }
}

@media (min-width: 400px) {
    .image-gallery-image img {
        min-height: 219px;
        max-height: 225px;
    }
}

@media (min-width: 411px) {
    .image-gallery-image img {
        min-height: 225px;
        max-height: 227px;
    }
}

@media (min-width: 414px) {
    .image-gallery-image img {
        min-height: 227px;
        max-height: 265px;
    }
}

@media (min-width: 480px) {
    .image-gallery-image img {
        min-height: 265px;
        max-height: 317px;
    }
}

@media (min-width: 568px) {
    .image-gallery-image img {
        min-height: 317px;
        max-height: 336px;
    }
}

@media (min-width: 600px) {
    .image-gallery-image img {
        min-height: 336px;
        max-height: 360px;
    }
}

@media (min-width: 640px) {
    .image-gallery-image img {
        min-height: 360px;
        max-height: 376px;
    }
}

@media (min-width: 667px) {
    .image-gallery-image img {
        min-height: 376px;
        max-height: 413px;
    }
}

@media (min-width: 731px) {
    .image-gallery-image img {
        min-height: 413px;
        max-height: 416px;
    }
}

@media (min-width: 736px) {
    .image-gallery-image img {
        min-height: 416px;
        max-height: 418px;
    }
}

@media (min-width: 740px) {
    .image-gallery-image img {
        min-height: 418px;
        max-height: 434px;
    }
}

/*
    Page has TWO COLUMNS
 */

@media (min-width: 768px) {
    .image-gallery-image img {
        height: 215px;
        object-fit: cover;

        min-height: unset;
        max-height: unset;
    }
}

@media (min-width: 800px) {
    .image-gallery-image img {
        height: 234px;
    }
}

@media (min-width: 812px) {
    .image-gallery-image img {
        height: 241px;
    }
}

@media (min-width: 824px) {
    .image-gallery-image img {
        height: 248px;
    }
}

@media (min-width: 847px) {
    .image-gallery-image img {
        height: 261px;
    }
}

@media (min-width: 850px) {
    .image-gallery-image img {
        height: 263px;
    }
}

@media (min-width: 853px) {
    .image-gallery-image img {
        height: 265px;
    }
}

@media (min-width: 896px) {
    .image-gallery-image img {
        height: 290px;
    }
}

@media (min-width: 960px) {
    .image-gallery-image img {
        height: 327px;
    }
}

@media (min-width: 990px) {
    .image-gallery-image img {
        height: 345px;
    }
}

@media (min-width: 1024px) {
    .image-gallery-image img {
        height: 365px;
    }
}

@media (min-width: 1080px) {
    .image-gallery-image img {
        height: 399px;
    }
}

@media (min-width: 1150px) {
    .image-gallery-image img {
        height: 410px;
    }
}

@media (min-width: 1220px) {
    .image-gallery-image img {
        height: 460px;
    }
}

@media (min-width: 1280px) {
    .image-gallery-image img {
        height: 527px;
    }
}

.image-gallery-thumbnails-wrapper {
    min-height: 120px;
}

.fullscreen-modal .image-gallery-thumbnails-wrapper {
    display: none;
}

.image-gallery-content .image-gallery-description {
    display: none;
}

.archive-image {
    opacity: 0.3;
}

/*
    FULLSCREEN
 */
.image-gallery.fullscreen-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.image-gallery.fullscreen-modal .image-gallery-content,
.image-gallery.fullscreen-modal .image-gallery-content.fullscreen,
.image-gallery.fullscreen-modal .image-gallery-slide-wrapper,
.image-gallery.fullscreen-modal .image-gallery-swipe,
.image-gallery.fullscreen-modal .image-gallery-slides,
.image-gallery.fullscreen-modal .image-gallery-slide,
.image-gallery.fullscreen-modal .image-gallery-image {
    position: absolute;
    display: grid;
    background: none;

    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;

    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
}

.image-gallery.fullscreen-modal .image-gallery-slide img {
    margin: auto;
    object-fit: contain;
    height: 100%;
}

.image-gallery.fullscreen-modal
    .image-gallery-image
    .image-gallery-description {
    position: absolute;
    display: block;
    bottom: unset;

    width: 100%;
    text-align: center;

    color: #fff;
    background: rgba(0, 0, 0, 0.4);
    line-height: 1;
    padding: 10px 20px;
    white-space: normal;
}

/*
    BUTTONS: PREV / NEXT
 */
.btn-prev,
.btn-next,
.image-gallery-left-nav,
.image-gallery-right-nav,
.image-gallery-fullscreen-button,
.image-gallery-enlarge-button,
.image-gallery-play-button,
.image-gallery-index,
.image-gallery-index-standard {
    z-index: 1;
}

.image-gallery-fullscreen-button {
    z-index: 2 !important;
    top: 5px !important;
    bottom: unset !important;
}

.image-gallery-fullscreen-button::before {
    padding: 10px !important;
}

.image-gallery.fullscreen-modal .image-gallery-fullscreen-button {
    top: 40px !important;
}

.image-gallery-fullscreen-button::before,
.image-gallery-play-button::before {
    font-size: 2.8em;
}

.btn-prev,
.btn-next {
    position: absolute;
    width: 12%;
    height: 100%;
    border: 0;
    padding: 0;
    cursor: pointer;
    background: transparent;
}

.btn-prev:focus,
.btn-next:focus {
    outline: none;
}

.btn-prev {
    left: 0;
    top: 0;
}

.btn-next {
    right: 0;
    top: 0;
}

.btn-prev:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 20px;
    width: 24px;
    height: 24px;
    margin-top: -16px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(135deg);
}

.btn-next:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    width: 24px;
    height: 24px;
    margin-top: -16px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(-45deg);
}

@media (min-width: 768px) {
    .btn-next:hover:after,
    .btn-prev:hover:after {
        border-color: #2ca5e0;
    }
}

.image-gallery-fullscreen-button,
.image-gallery-fullscreen-button:after,
.image-gallery-fullscreen-button:before,
.image-gallery-fullscreen-button:hover,
.image-gallery-fullscreen-button:active,
.image-gallery-fullscreen-button:focus {
    outline: 0;
}
